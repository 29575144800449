<template>
  <v-container
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
    fluid
  >
    
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar :to="`/areas/${$route.params.idArea}/tabela`" />
        </v-col>
      </v-row>
    </v-card-actions>
    <tabela-campos
      :carregando-salvar="carregandoSalvar"
      :readonly="readonly"
      :validacao-formulario="validacaoFormulario"
      :tabela="tabela"
      salvarDisabled
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import tabelas from "@/api/tabelas";

export default {
  components: {
    TabelaCampos: () => import("./TabelaCampos"),
  },

  data() {
    return {
      carregandoSalvar: false,
     
      salvarDisabled: false,
      readonly: true,
      validacaoFormulario: {},
      tabela: {},
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.salvarDisabled = true;
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await tabelas.buscar(
          this.$route.params.idArea,
          this.$route.params.id
        );

        var tabela = {
          descricao: resposta.data.data.descricao,
          ativo: resposta.data.data.ativo,
          valor: resposta.data.data.valor,
          tempo: resposta.data.data.tempo,
          padrao: resposta.data.data.padrao,
          tem_convenio: resposta.data.data.tem_convenio,
          tipo_veiculo_id: resposta.data.data.tipo_veiculo_id,
          minutos_tolerancia: resposta.data.data.minutos_tolerancia,
          valor_adicional: resposta.data.data.valor_adicional,
          tempo_fracional: resposta.data.data.tempo_fracional,
          dia_id: resposta.data.data.dia_id,
        };
        console.log(tabela, "tabela");
        this.tabela = tabela;
      } catch (e) {
        this.$router.push(`/areas/${this.$route.params.idArea}/tabela`, () =>
          this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(tabela) {
      console.log(tabela, "put");
      this.carregandoSalvar = true;

      try {
        await tabelas.atualizar(
          this.$route.params.idArea,
          this.$route.params.id,
          tabela
        );
        this.$router.push(`/areas/${this.$route.params.idArea}/tabela`, () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_editar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = e.response.data.erros;
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        return;
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
